import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import {
  secureBeforeEnter
} from "../util/auth-util";
import store from "../store";
Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "Home",
  component: Home
},
{
  path: "/weryfikacja",
  name: "PdfVerification",
  component: () =>
    import( /* webpackChunkName: "pdfverification" */ "@/views/PdfVerification.vue")
},
{
  path: "/polityka-prywatnosci",
  name: "PrivacyPolicy",
  component: () =>
    import( /* webpackChunkName: "privacy" */ "../views/Page.vue"),
  props: {
    title: "Polityka prywatności",
    code: "privacy"
  }
},
{
  path: "/deklaracja-dostepnosci",
  name: "AccessibilityDeclaration",
  component: () =>
    import( /* webpackChunkName: "accessibility" */ "../views/Page.vue"),
  props: {
    title: "Deklaracja dostępności",
    code: "accessibility"
  }
},

{
  path: "/dowiedz-sie-wiecej",
  name: "More",
  component: () =>
    import( /* webpackChunkName: "more" */ "../views/Page.vue"),
  props: {
    title: "Dowiedz się więcej",
    code: "more"
  }

},
{
  path: "*",
  name: 'PageNotFound',
  component: () => import('@/views/PageNotFound')
},
{
  path: '/pb-12',
  redirect: to => {
    let redirect = router.resolve({
      name: "AccountBuildingLogProposalAdd",
    });
    return redirect.href;
  }
},
{
  path: "/konto",
  name: "Account",
  component: () =>
    import( /* webpackChunkName: "account" */ "../views/Account.vue"),
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: '',
      name: 'OnLoginRedirect',
      component: () => import( /* webpackChunkName: 'onloginredirect' */ '@/views/OnLoginRedirect'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'szybki-start',
      name: 'AccountStart',
      component: () => import( /* webpackChunkName: 'accountstart' */ '@/views/AccountStart'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'rejestr-dziennikow-budowlanych',
      name: 'AccountBuildingLogRegistry',
      component: () => import( /* webpackChunkName: 'accountbuildinglogregistry' */ '@/views/AccountBuildingLogRegistry'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'dziennik-budowy',
      name: 'AccountBuildingLogRegistryUser',
      component: () => import( /* webpackChunkName: 'accountbuildinglogregistryuser' */ '@/views/AccountBuildingLogRegistryUser'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/strona-tytulowa',
      name: 'AccountBuildingTitlePage',
      component: () => import( /* webpackChunkName: 'accountbuildingtitlepage' */ '@/views/AccountBuildingTitlePage'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'dziennik-budowy/:id/archiwum',
      name: 'AccountBuildingTitlePageArchival',
      component: () => import( /* webpackChunkName: 'accountbuildingtitlepagearchival' */ '@/views/AccountBuildingTitlePageArchival'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/uczestnicy-procesu-budowlanego',
      name: 'AccountBuildingLogParticipants',
      component: () => import( /* webpackChunkName: 'accountbuildinglogparticipants' */ '@/views/AccountBuildingLogParticipants'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/uczestnik-procesu-budowlanego/:participantId',
      name: 'AccountBuildingLogParticipant',
      component: () => import( /* webpackChunkName: 'accountbuildinglogparticipant' */ '@/views/AccountBuildingLogParticipant'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/nowy-uczestnik-procesu-budowlanego/:hash',
      name: 'AccountBuildingLogNewParticipant',
      component: () => import( /* webpackChunkName: 'accountbuildinglognewparticipant' */ '@/views/AccountBuildingLogNewParticipant'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/wpis/:entryLogId',
      name: 'AccountBuildingLogEntry',
      component: () => import( /* webpackChunkName: 'accountbuildinglogentry' */ '@/views/AccountBuildingLogEntry'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/uczestnik-procesu-budowlanego',
      name: 'AccountBuildingLogAddParticipant',
      component: () => import( /* webpackChunkName: 'accountbuildinglogaddparticipant' */ '@/views/AccountBuildingLogAddParticipant'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/przedstawiciel-organu-administracji',
      name: 'AccountBuildingLogAddRepresentative',
      component: () => import( /* webpackChunkName: 'accountbuildinglogaddrepresentative' */ '@/views/AccountBuildingLogAddRepresentative'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy/:id/wpis',
      name: 'AccountBuildingLogEntries',
      component: () => import( /* webpackChunkName: 'accountbuildinglogentries' */ '@/views/AccountBuildingLogEntries'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy',
      name: 'AccountBuildingLogAdd',
      component: () => import( /* webpackChunkName: 'accountbuildinglogadd' */ '@/views/AccountBuildingLogAdd'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'powiadomienia',
      name: 'AccountNotification',
      component: () => import( /* webpackChunkName: 'accountnotification' */ '@/views/AccountNotification'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy-wniosek',
      name: 'AccountBuildingLogProposalAdd',
      component: () => import( /* webpackChunkName: 'accountbuildinglogproposaladd' */ '@/views/AccountBuildingLogProposal'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy-wniosek-wprowadzanie',
      name: 'AccountBuildingLogProposalEnter',
      component: () => import( /* webpackChunkName: 'accountbuildinglogproposalenter' */ '@/views/AccountBuildingLogProposal'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy-wniosek/:id',
      name: 'AccountBuildingLogProposalEdit',
      component: () => import( /* webpackChunkName: 'accountbuildinglogproposaledit' */ '@/views/AccountBuildingLogProposal'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy-wnioski',
      name: 'AccountBuildingLogProposalList',
      component: () => import( /* webpackChunkName: 'accountbuildinglogproposallist' */ '@/views/AccountBuildingLogProposalList'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dziennik-budowy-wnioski-uzytkownika',
      name: 'AccountBuildingLogProposalListUser',
      component: () => import( /* webpackChunkName: 'accountbuildinglogproposallistuser' */ '@/views/AccountBuildingLogProposalList'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'dane-uzytkownika',
      name: 'AccountUserProfile',
      component: () => import( /* webpackChunkName: 'accountuserprofile' */ '@/views/AccountUserProfile'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'admin/utworz-uzytkownika',
      name: 'Register',
      component: () => import( /* webpackChunkName: 'register' */ '@/views/AccountUserProfile'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'zmiana-hasla',
      name: 'AccountUserPassdChange',
      component: () => import( /* webpackChunkName: 'accountuserpassdchange' */ '@/views/AccountUserPassdChange'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'report',
      name: 'AccountReports',
      component: () => import( /* webpackChunkName: 'accountreports' */ '@/views/AccountReports'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'admin/slowniki',
      name: 'AccountAdminDictionaryMgmt',
      component: () => import( /* webpackChunkName: 'accountadmindictionarymgmt' */ '@/views/AccountAdminDictionaryMgmt'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'admin/uprawnienia',
      name: 'AccountAdminPermisionMgmt',
      component: () => import( /* webpackChunkName: 'accountadminpermisionmgmt' */ '@/views/AccountAdminPermisionMgmt'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'admin/uprawnienia/grupy',
      name: 'AccountAdminPermisionMgmtRoleGroup',
      component: () => import( /* webpackChunkName: 'accountadminpermisionmgmtrolegroup' */ '@/views/AccountAdminPermisionMgmtRoleGroup'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'admin/uprawnienia/user',
      name: 'AccountAdminPermisionMgmtRoleUser',
      component: () => import( /* webpackChunkName: 'accountadminpermisionmgmtroleuser' */ '@/views/AccountAdminPermisionMgmtRoleUser'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'admin/api',
      name: 'AccountAdminApiUsers',
      component: () => import( /* webpackChunkName: 'accountadminapiusers' */ '@/views/AccountAdminApiUsers'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'admin/role',
      name: 'AccountAdminRoleMgmt',
      component: () => import( /* webpackChunkName: 'accountadminrolemgmt' */ '@/views/AccountAdminRoleMgmt'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'admin/przerwa-techniczna',
      name: 'AccountAdminTechnicalBreak',
      component: () => import( /* webpackChunkName: 'accountadmintechnicalbreak' */ '@/views/AccountAdminTechnicalBreak'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'admin/narzedzia',
      name: 'AccountAdminTools',
      component: () => import( /* webpackChunkName: 'accountadmintools' */ '@/views/AccountAdminTools'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'admin/uzytkownicy',
      name: 'AccountAdminUserMgmt',
      component: () => import('@/views/AccountAdminUserMgmt'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'admin/audyt',
      name: 'AccountAdminAuditLog',
      component: () => import( /* webpackChunkName: 'accountadminauditlog' */ '@/views/AccountAdminAuditLog'),
      meta: {
        requiresAuth: true
      }
    },

    {
      path: 'admin/weryfikacja/audyt',
      name: 'AccountAdminPdfVerificationLog',
      component: () => import( /* webpackChunkName: 'accountadminpdfverificationlog' */ '@/views/AccountAdminPdfVerificationLog'),
      meta: {
        requiresAuth: true
      }
    },


  ],

}
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});


router.beforeEach(secureBeforeEnter(store));

export default router;