<template>
  <v-app>
    <the-header />
    <router-view />
    <the-footer />
    <!-- wspólny overlay -->
    <v-overlay style="z-index: 9999999999"
               :value="overlay">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
    <!-- wspólne okienko z komunikatem -->
    <v-dialog v-model="dialog.show"
              width="500">
      <v-card>
        <v-card-title>
          {{ dialog.title }}
        </v-card-title>

        <v-card-text v-html="dialog.text">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn title="OK"
                 aria-label="OK"
                 color="primary"
                 text
                 @click="$store.commit('layout/hideDialog')">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- wspólne okienko z zapytaniem -->
    <v-dialog v-model="askDialog.show"
              width="500">
      <v-card>
        <v-card-title>
          {{ askDialog.title }}
        </v-card-title>

        <v-card-text>
          <span v-html="askDialog.text"></span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :title="askDialog.noLabel"
                 :aria-label="askDialog.noLabel"
                 text
                 @click="$store.commit('layout/hideAskDialog')">
            {{ askDialog.noLabel }}
          </v-btn>
          <v-btn :title="askDialog.yesLabel"
                 :aria-label="askDialog.yesLabel"
                 color="primary"
                 v-if="askDialog.yesClick"
                 @click="askDialog.yesClick">
            {{ askDialog.yesLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- powiadomienia -->
    <snackbar-notification v-if="isLogin"></snackbar-notification>
  </v-app>
</template>

<script>
import TheFooter from "@/components/TheFooter";
import TheHeader from "@/components/TheHeader";
import { mapGetters } from "vuex";
import SnackbarNotification from "@/components/SnackbarNotification";
import offlineStoreDetect from "@/pwa/offline-store-detect";

export default {
  name: "App",
  components: { TheHeader, TheFooter, SnackbarNotification },
  computed: {
    ...mapGetters({
      overlay: "layout/getOverlay",
      dialog: "layout/getDialog",
      askDialog: "layout/getAskDialog",
    }),
  },
  data: () => {
    return {
      firstTime: true
    }
  },
  watch: {
    isLogin (val) {
      if (val && this.firstTime) {
        this.firstTime = false;
        setInterval(() => {
          this.getNotificationNews();
        }, 4000);
      }
    },
  },
  methods: {
    async getNotificationNews () {
      let { data } = await this.$axios.get("/notification/news");
      // this.$store.getters["dict/getEntry"]
      this.$store.commit("auth/setMessages", data ? data.length : 0);
    },
  },
  mounted () {
    if (this.isLogin && this.firstTime) {
      this.firstTime = false;
      if (process.env.NODE_ENV != 'dev')
        setInterval(() => {
          this.getNotificationNews();
        }, 4000);
    }

    offlineStoreDetect(this.$store);

  },
};
</script>