<template>
  <v-main>
    <v-snackbar top
                z-index="100000"
                color="success"
                v-model="snackbar.show"
                :timeout="4000">
      {{snackbar.msg}}
    </v-snackbar>
    <section id="landing-hero"
             class="mb-6  mb-md-12">
      <v-img :height="isMobile?'35vh':'70vh'"
             srcset="/img/landing-hero-edb.webp 1920w, /img/landing-hero-edb_800.webp 800w, /img/landing-hero-edb_320.webp 320w"
             sizes="100vw"
             src="/img/landing-hero-edb.jpg"
             gradient="#fff0, rgb(21 45 105 / 50%)">
        <v-row align="end"
               style="height: 90%;"
               class="px-5 px-md-12">
          <v-col cols="12"
                 md="4">
            <h1 class="text-title text-md-h3 font-weight-medium accent--text">
              <strong>E-DZIENNIK</strong>
            </h1>
            <h2 class="text-title text-md-h3 font-weight-medium   white--text">Prowadź dziennik budowy <span class="underline--accent">elektronicznie</span></h2>
          </v-col>
          <v-col cols="12"
                 md="8">
            <v-btn class="text-title text-md-h3 pa-5 pa-md-10 float-right"
                   :to="{name:'More'}"
                   title="Dowiedz się więcej!"
                   color="primary">Dowiedz się więcej!</v-btn>
          </v-col>
        </v-row>
      </v-img>
    </section>
    <v-row align="center"
           class="ma-0 mb-6 mb-md-12"
           justify="center">
      <v-btn title="Przewiń w dół"
             color="accent"
             fab
             x-large
             text
             @click="$vuetify.goTo('#about', options)">
        <v-icon x-large>mdi-arrow-down-drop-circle-outline </v-icon>
      </v-btn>
    </v-row>
    <section id="about"
             class="mb-6 mb-md-12 primary-txt--text">
      <v-container class="mb-2">
        <div v-html="content" />
      </v-container>
    </section>
  </v-main>
</template>

<script>
import snackbarMixin from "@/mixins/snackbar-mixin.js";
export default {
  name: "Home",
  mixins: [snackbarMixin],
  data: () => {
    return {
      options: {
        duration: 1000,
        offset: 2,
        easing: "linear",
      },
      content: null
    }
  },
  methods: {
    loadContent () {
      this.$store.commit("layout/showOverlay");
      this.$axios
        .get(`/configurations/pages/main`)
        .then(({ data }) => {
          this.content = data.content;
        })
        .catch((e) => {
          console.log(e.response);
          this.$store.commit(
            "layout/showErrorDialog",
            "Nie udało się pobrać danych."
          );
        })
        .finally(() => {
          this.$store.commit("layout/hideOverlay");
        });
    }
  },
  created () {
    this.loadContent();
    if (this.$route.query.msg) {
      this.snackbar.msg = this.$route.query.msg;
      this.snackbar.show = true;
    }
    if (this.$route.query.complete_registration) {
      let adm = String(this.$route.query.administration)
      if (adm == 'false')
        this.$store.commit("layout/showAskDialog", {
          yesClick: () => {
            let props = this.$router.resolve({
              name: 'Account',
            });
            let path = window.location.href.split(/[?#]/)[0];
            if (path.endsWith("/"))
              path = path.slice(0, -1);
            this.$store.dispatch('auth/login', { redirectUri: path + props.href })
          },
          yesLabel: "Zaloguj",
          noLabel: "Anuluj",
          title: "Potwierdzenie konta",
          text: `Twoje konto zostało aktywowane. Możesz rozpocząć pracę w systemie Elektronicznego Dziennika Budowy`,
        })
      else {
        this.$store.commit("layout/showAskDialog", {
          yesClick: () => {
          },
          yesLabel: "OK",
          noLabel: "Anuluj",
          title: "Potwierdzenie konta",
          text: `Twoje konto zostało wstępnie aktywowane. Rozpoczęcie pracy w systemie Elektronicznego Dziennika Budowy będzie możliwe po jego zatwierdzeniu przez Administratora.`,
        })
      }
    }
  }
};
</script>
