import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pl from 'vuetify/es5/locale/pl'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify)
export default new Vuetify({
  lang: {
    locales: {
      pl
    },
    current: 'pl'
  },
  theme: {
    themes: {
      light: {
        primary: "#152D69",
        "primary-txt": "#152D69",
        "secondary": "#F2F2F2",
        "accent": "#9E211E",
        "info": "#030A1F",
        "error": "#B30000",
        "success": "#006629",
        "warning": "#B40404",
        "forwarded": "#800080"
      },
      dark: {
        base: "#8D95C9",
        "primary-txt": "#aeaeae",
        "secondary": "#272727",
        "accent": "#ffc100",
        "info": "#030A1F",
        "error": "#FF6161",
        "success": "#00AD45",
        "warning": "#FC6464",
        "forwarded": "#00FFFF"
      }
    },
    options: {
      customProperties: true
    },
  },
  icons: {
    iconfont: 'mdi'
  }
})